a, a:hover {
    text-decoration: none;
}

.btn-secondary, .btn-secondary:hover {
    color: #75B4A9
}

.btn-primary, .btn-primary:hover {
    color: white
}

.btn-outline-primary:hover {
    color: white
}

.btn-outline-secondary:hover {
    color: white
}

.btn-outline-light:hover {
    color: #75B4A9
}

.badge {
    color: white;
}

.StripeElement {
    display: block;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    outline: 0;
    border: 1px solid #ced4da;
    background: white;
}

.font-weight-light {
    font-family: "AvenirLTStd-Light", serif;
}

