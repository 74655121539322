.nav-link {
    /*letter-spacing: 1px;*/
    font-weight: 500;
    font-size: 20px;
    color: #75B4A9 !important;
}

.section-extra {
    transform: skewX(-50deg)
}

.section-extra > * {
    transform: skewX(50deg);
}

.tirita {
    transform: skewX(50deg);
    width: 100px;
    right: 0;
}

.section-extra .nav-link {
    color: white;
    font-size: 15px;
    text-align: right;
}

.section-extra .nav-link:hover {
    color: #75B4A9;
}


.title{
    letter-spacing: 2px;
    position: absolute;
    top: 10px;
    right: 0;
    fontSize: 18px;
}
/* .border {
    color: #75B4A9  !important;
    background-color:  #75B4A9 !important;
    opacity: 0.7;
    /* border-inline-color: 1px solid salmon !important; */
/* } */
